import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { ProvideAuth } from 'thrivent-ui-library';
import App from './App';
import i18n from './i18n';
import createTheme from './theme';
import { HeaderProvider } from './hooks/useHeader';

ReactDOM.render(
  <MuiThemeProvider theme={createTheme}>
    <I18nextProvider i18n={i18n}>
      <HeaderProvider>
        <Router>
          <ProvideAuth>
            <CssBaseline />
            <App />
          </ProvideAuth>
        </Router>
      </HeaderProvider>
    </I18nextProvider>
  </MuiThemeProvider>,
  document.getElementById('root')
);
