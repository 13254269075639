import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  RadioGroup,
  Radio,
  IconButton,
  FormControlLabel,
  Typography,
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { Profile } from '../../types';

const styles = () =>
  createStyles({
    bold: {
      fontWeight: 'bold'
    },

    closeButton: {},

    radioLabel: {}
  });

interface Props extends WithStyles<typeof styles> {
  profile: Profile;
  patchProfile: (data: Profile) => void;
}

enum PhoneType {
  None = '',
  Mobile = 'mobile',
  Home = 'home',
  Work = 'work'
}

interface FormProps {
  phoneType: PhoneType;
}

const DuplicatePhoneModal: React.FC<Props> = ({ classes, profile, patchProfile }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<null | false | true>(null);

  const getValue = <T, K extends keyof T>(obj: T, key: K) => {
    return obj[key];
  };

  const phoneNumbers = [profile.mobilePhone, profile.homePhone, profile.workPhone].filter(n => !!n);
  const uniquePhoneNumbers = new Set(phoneNumbers);

  const hasDuplicateNumbers = phoneNumbers.length > uniquePhoneNumbers.size;

  const displayDuplicatePhoneModal = open || (open === null && hasDuplicateNumbers);
  if (!displayDuplicatePhoneModal) return null;

  const profileAttributes = ['mobilePhone', 'homePhone', 'workPhone'].filter(attribute => {
    const value = getValue(profile, attribute);
    const isDuplicate = phoneNumbers.filter(p => p === value).length > 1;
    return isDuplicate;
  });

  const yupSchema = Yup.object().shape({
    phoneType: Yup.string()
  });

  if (!displayDuplicatePhoneModal) return null;

  return (
    <Formik
      initialValues={{ phoneType: PhoneType.None }}
      validationSchema={yupSchema}
      onSubmit={(params: { phoneType: string }) => {
        const { phoneType } = params;
        const updatedPhoneAttributes = profileAttributes.reduce((accum, profileAttribute: string) => {
          return {
            ...accum,
            ...(profileAttribute === phoneType && { [profileAttribute]: getValue(profile, profileAttributes[0]) }),
            ...(profileAttribute !== phoneType && { [profileAttribute]: '' })
          };
        }, {});
        patchProfile({ ...profile, ...updatedPhoneAttributes });
        setOpen(false);
      }}
    >
      {({ values, handleChange, handleSubmit, submitForm, dirty }: FormikProps<FormProps>) => {
        const changePhoneValue = (evt: any) => {
          handleChange(evt);
        };

        return (
          <form action="/manage-my-profile" onSubmit={handleSubmit}>
            <Dialog fullWidth open>
              <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  {t('duplicatePhoneModal:title')}
                  <IconButton aria-label="close" onClick={() => setOpen(false)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </DialogTitle>
              <DialogContent dividers>
                <Box display="flex" flexDirection="column">
                  <Box margin="1rem 0">
                    <Typography variant="body1">{t('duplicatePhoneModal:instruction')}</Typography>
                  </Box>
                  <Box margin="1rem 0">
                    <Typography variant="body1" classes={{ root: classes.bold }}>
                      {t('duplicatePhoneModal:phone', { value: getValue(profile, profileAttributes[0]) })}
                    </Typography>
                  </Box>
                  <Box margin="0 0 2rem">
                    {profileAttributes.map(attribute => {
                      return (
                        <RadioGroup
                          key={attribute}
                          aria-label="phone-type"
                          name="phoneType"
                          value={values.phoneType}
                          onChange={changePhoneValue}
                        >
                          <FormControlLabel
                            value={attribute}
                            control={<Radio />}
                            label={t('common:labels.phoneType', { context: attribute })}
                            classes={{ label: classes.radioLabel }}
                          />
                        </RadioGroup>
                      );
                    })}
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button type="submit" onClick={() => submitForm()} disabled={!dirty} fullWidth variant="contained">
                  {t('common:buttons.save')}
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        );
      }}
    </Formik>
  );
};

export default withStyles(styles)(memo(DuplicatePhoneModal));
