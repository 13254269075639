export default {
  heading: 'Manage My Profile',
  personalInformation: 'Personal Information',
  personalInformationSubtitle: 'Manage your contact information, address and other details.',
  view: 'View',
  learn: 'Learn More',
  login: 'Login Password',
  loginSubtitle: 'Make changes to your password.',
  loginSecurity: 'Login Security Information',
  loginSecuritySubtitle: 'Make changes security information.',
  communication: 'Preference Center',
  communicationSubtitle: 'Manage your communication choices.',
  financialProfile: 'Investment Profile',
  financialProfileSubtitle: 'Manage information related to your financial product registrations.',
  security: 'Privacy and Security Protection',
  securitySubtitle:
    'Your privacy and security are important to us. Our policies protect your personal information at every level and our legal disclosures tell you how we conduct business and work with our members to resolve issues.'
};
