import React, { memo, useEffect } from 'react';
import {
  createStyles,
  withStyles,
  Paper,
  Grid,
  Box,
  Link,
  Typography,
  WithStyles,
  Container,
  Theme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ManageMyProfileLink, { Topic } from './ManageMyProfileLink';
import { useHeader } from '../../hooks/useHeader';
import useProfile from '../../hooks/useProfile';
import Divider from '../Divider';

const styles = ({
  palette: {
    blue: { main: blueMain, dark: blueDark }
  },
  breakpoints
}: Theme) => {
  return createStyles({
    paper: {
      margin: '1rem',
      padding: '1.5rem',

      '&:first-child': {
        marginTop: 0
      },

      [breakpoints.up('md')]: {
        margin: '0 0 1.5rem',
        padding: '2rem'
      }
    },

    link: {
      '&:hover': {
        textDecoration: 'none'
      }
    },

    learnLink: {
      textAlign: 'left',
      color: `${blueMain}`,
      width: '100%',

      [breakpoints.up('md')]: {
        textAlign: 'center'
      },

      'a:hover &': {
        color: `${blueDark}`
      }
    },

    securityHeading: {
      fontSize: '0.875rem',

      [breakpoints.up('md')]: {
        fontSize: '1.25rem'
      }
    },

    securitySubtitle: {
      fontSize: '0.75rem',

      [breakpoints.up('md')]: {
        fontSize: '1rem'
      }
    }
  });
};

interface Props extends WithStyles<typeof styles> {}

const ManageMyProfile: React.FC<Props> = ({ classes }) => {
  const profile = useProfile();
  const { t } = useTranslation('manageMyProfile');
  const { setPageHeading } = useHeader();

  useEffect(() => {
    setPageHeading(t('heading'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container disableGutters>
      <Paper elevation={2} classes={{ root: classes.paper }}>
        <ManageMyProfileLink
          text={t('personalInformation')}
          subtext={t('personalInformationSubtitle')}
          topicName={Topic.personalInformation}
        />
      </Paper>
      <Paper elevation={2} classes={{ root: classes.paper }}>
        <ManageMyProfileLink
          text={t('communication')}
          subtext={t('communicationSubtitle')}
          topicName={Topic.communication}
        />
      </Paper>
      {profile && profile.hasFinancialProfile && (
        <Paper elevation={2} classes={{ root: classes.paper }}>
          <ManageMyProfileLink
            text={t('financialProfile')}
            subtext={t('financialProfileSubtitle')}
            topicName={Topic.financialProfile}
          />
        </Paper>
      )}

      <Box margin={{ xs: '1.5rem', md: '2.5rem 0' }}>
        <Divider />
      </Box>

      <Link href={`${window.WWW_DOMAIN}/privacy-and-security/`} classes={{ root: classes.link }}>
        <Grid container>
          <Grid item xs={12} md={9}>
            <Box margin={{ xs: '0 1rem', md: '0' }}>
              <Typography component="span" display="block" variant="h5" classes={{ root: classes.securityHeading }}>
                {t('security')}
              </Typography>
              <Box pt={1}>
                <Typography
                  component="span"
                  display="block"
                  variant="body2"
                  color="textPrimary"
                  classes={{ root: classes.securitySubtitle }}
                >
                  {t('securitySubtitle')}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid container item xs={12} md={3} justify="center" alignItems="center">
            <Box width="100%" margin={{ xs: '0.5rem 1rem 2.5rem', md: '1rem' }}>
              <Typography component="p" display="block" variant="subtitle2" classes={{ root: classes.learnLink }}>
                {t('learn')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Link>
    </Container>
  );
};

export default withStyles(styles)(memo(ManageMyProfile));
