import React, { memo, createContext, useContext, useState, Dispatch } from 'react';

type T = {
  pageHeading: string | undefined;
  setPageHeading: Dispatch<any>;
};
const HeaderContext = createContext<T>({ pageHeading: undefined, setPageHeading: () => {} });

type Props = {};

const HeaderProvider: React.FC<Props> = memo(({ children }) => {
  const [pageHeading, setPageHeading] = useState<string | undefined>();

  return <HeaderContext.Provider value={{ pageHeading, setPageHeading }}>{children}</HeaderContext.Provider>;
});

const useHeader = () => useContext(HeaderContext);

export { HeaderProvider, useHeader };
