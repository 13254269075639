import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  IconButton,
  Typography,
  WithStyles,
  withStyles,
  createStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles = () =>
  createStyles({
    dialogContentDividers: {
      borderBottom: 'none',
      paddingTop: '1.5rem',
      paddingBottom: '4rem'
    },

    button: {
      padding: '0.75rem'
    }
  });

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  dismiss: () => void;
}

const DuplicatePhoneModal: React.FC<Props> = ({ classes, open, dismiss }) => {
  const { t } = useTranslation();

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">{t('invalidAddressModal:title')}</Typography>
          <IconButton aria-label="close" onClick={dismiss}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers classes={{ dividers: classes.dialogContentDividers }}>
        <Box display="flex" flexDirection="column">
          <Typography variant="body1">{t('invalidAddressModal:instruction')}</Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={dismiss} classes={{ root: classes.button }}>
          {t('invalidAddressModal:tryAgain')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(memo(DuplicatePhoneModal));
