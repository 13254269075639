import React, { useState, memo } from 'react';
import { withStyles, Theme, IconButton, Tooltip, TooltipProps, WithStyles, createStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

const styles = ({
  palette: {
    common: { white, black },
    blue: { main: mainBlue }
  }
}: Theme) =>
  createStyles({
    tooltip: {
      backgroundColor: white,
      color: black,
      border: `1px solid ${mainBlue}`,
      // padding: '0.5rem',
      fontSize: '1rem'
    },

    tooltipicon: {
      fontSize: '1rem',
      padding: '0.5rem',

      '&:hover': {
        backgroundColor: white
      },

      '& svg': {
        fontSize: '1rem'
      }
    },

    arrow: {
      color: mainBlue
    }
  });

interface Props extends Omit<TooltipProps, 'classes' | 'children'>, WithStyles<typeof styles> {}

const InfoTooltip: React.FC<Props> = props => {
  const { classes } = props;
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(prev => !prev);
  const onClose = () => setOpen(false);

  return (
    <Tooltip
      arrow
      open={open}
      interactive
      onClose={onClose}
      disableHoverListener
      leaveTouchDelay={20_000}
      {...props}
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
    >
      <IconButton color="secondary" disableRipple onClick={toggleOpen} classes={{ root: classes.tooltipicon }}>
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );
};

export default withStyles(styles)(memo(InfoTooltip));
