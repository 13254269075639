import React, { memo } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Container,
  Typography,
  Link,
  withStyles,
  createStyles,
  WithStyles,
  Theme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, Link as RouterLink } from 'react-router-dom';
import { useHeader } from '../../hooks/useHeader';

const styles = ({
  breakpoints,
  palette: {
    common: { white },
    gold: { main: goldMain }
  }
}: Theme) => {
  return createStyles({
    wrapper: {
      backgroundColor: white,
      width: '100%',
      padding: '5rem 0',
      boxShadow: `0px 1px 0px ${goldMain}`,
      position: 'relative',
      zIndex: 10
    },

    heading: {
      textAlign: 'center',

      [breakpoints.up('md')]: {
        textAlign: 'left'
      }
    },

    list: {
      display: 'none',

      [breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'row'
      }
    },

    listItem: {
      width: 'auto',
      flexBasis: 'auto',
      flexShrink: 0,

      '&:after': {
        margin: '0 1rem',
        color: 'silver',
        content: '">"'
      },

      '&:last-child:after': {
        content: 'unset'
      },

      '& a': {
        color: 'silver'
      },

      '&:last-child a': {
        color: 'black'
      }
    }
  });
};

interface Props extends WithStyles<typeof styles> {}

const SectionHeader: React.FC<Props> = ({ classes }) => {
  const { t } = useTranslation('common');
  const { pageHeading } = useHeader();
  const pipRouteMatch = useRouteMatch('/manage-my-profile/personal-information');

  return (
    <section className={classes.wrapper}>
      <Container>
        <List classes={{ root: classes.list }}>
          <ListItem disableGutters classes={{ root: classes.listItem }}>
            <ListItemText>
              <Link href={`${window.WWW_DOMAIN}`} variant="caption" color="textPrimary">
                {t('links.home')}
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem disableGutters classes={{ root: classes.listItem }}>
            <ListItemText>
              <Link href={`${window.SERVICE_DOMAIN}/portal/mythrivent`} variant="caption" color="textPrimary">
                {t('links.myThrivent')}
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem disableGutters classes={{ root: classes.listItem }}>
            <ListItemText>
              <Link href="/manage-my-profile" variant="caption" color="textPrimary">
                {t('links.manageMyProfile')}
              </Link>
            </ListItemText>
          </ListItem>

          {pipRouteMatch && (
            <ListItem disableGutters classes={{ root: classes.listItem }}>
              <ListItemText>
                <Link
                  to="/manage-my-profile/personal-information"
                  component={RouterLink}
                  variant="caption"
                  color="textPrimary"
                >
                  {t('links.personalInformationPage')}
                </Link>
              </ListItemText>
            </ListItem>
          )}
        </List>

        <Typography variant="h1" classes={{ root: classes.heading }}>
          {pageHeading}
        </Typography>
      </Container>
    </section>
  );
};

export default withStyles(styles)(memo(SectionHeader));
