import { Theme as SharedTheme } from 'thrivent-ui-library';

const createTheme = (() => {
  return {
    ...SharedTheme,
    overrides: {
      ...SharedTheme.overrides,
      MuiContainer: {
        root: {
          paddingLeft: '1rem',
          paddingRight: '1rem',

          [SharedTheme.breakpoints.up('md')]: {
            paddingLeft: 0,
            paddingRight: 0
          }
        }
      }
    }
  };
})();

createTheme.breakpoints.values = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1200,
  xl: 1200
};

export default createTheme;
