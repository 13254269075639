/* eslint-disable react/jsx-wrap-multilines */
import React, { memo, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Paper, Box, Typography, WithStyles, withStyles, createStyles, Theme } from '@material-ui/core';
import { usePatch } from 'thrivent-ui-library';
import { useHistory } from 'react-router-dom';
import DefinitionTerm from '../DefinitionTerm';
import useProfile from '../../hooks/useProfile';
import { useHeader } from '../../hooks/useHeader';
import ProfileInformationForm from '../ProfileInformationForm';
import GeneralInformationForm from '../GeneralInformationForm';
import ContactInformationForm from '../ContactInformationForm';
import AddressInformationForm from '../AddressInformationForm';
import { Profile, ProfileForm, ApiError, JsonResponse, AddressType } from '../../types';
import RelatedLinkCards from '../RelatedLinkCards';
import useProfileValues from '../../hooks/useProfileValues';
import Divider from '../Divider';
import InvalidAddressModal from '../InvalidAddressModal';

const styles = ({ breakpoints }: Theme) =>
  createStyles({
    caption: {
      margin: '1rem 0',

      [breakpoints.up('md')]: {
        margin: '1rem 0 1.5rem'
      }
    },

    paper: {
      padding: '1rem',
      margin: '0 1rem',

      [breakpoints.up('md')]: {
        padding: '2.5rem',
        margin: 0
      }
    }
  });

interface Props extends WithStyles<typeof styles> {}

const CIF_ADDRESS_ERRORS = [
  { code: '04276', description: 'ZIP CODE NOT FOUND/CHK CODE-1' },
  { code: '04275', description: 'CHK STREET FOR CORRECTNESS' },
  { code: 'E0106', description: 'Customer Address did not pass validation.' },
  { code: '06591', description: 'MAILING ADDR ENTERED AS RESID' },
  { code: '06595', description: 'NEED CITY/ST/ZIP OR COUNTRY' },
  { code: '04273', description: 'HOUSE/APT NBR WRONG/CHK CODE1' },
  { code: '07225', description: 'DUPLICATE ADDRESS FOR CUSTOMER' }
];

const ProfileInformation: React.FC<Props> = ({ classes }) => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const readOnlyProfile = useProfile();
  const { maritalStatusCodes } = useProfileValues();
  const patch = usePatch<JsonResponse<Profile>, ApiError>();
  const { execute: executePatch, data: patchData, error, loading } = patch;
  const httpStatusCode = error?.response?.status;
  const { errors } = patchData || {};
  const [profile, setProfile] = useState<Profile | null>(null);
  const [profileDiff, setProfileDiff] = useState<string[]>([]);
  const [hasAddressError, setHasAddressError] = useState<boolean>(false);
  const [activeForm, setActiveForm] = useState<ProfileForm>(ProfileForm.None);
  const { setPageHeading } = useHeader();

  useEffect(() => {
    setPageHeading(t('home.heading'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (httpStatusCode && httpStatusCode >= 500) history.push('/manage-my-profile/service-unavailable');
  }, [history, httpStatusCode]);

  useEffect(() => {
    const cifErrorFound = errors?.find(({ status: errorHttpStatusCode, code }) => {
      return CIF_ADDRESS_ERRORS.find(({ code: addressErrorCode }) => {
        return errorHttpStatusCode === 422 && code === addressErrorCode;
      });
    });

    setHasAddressError(!!cifErrorFound);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    setProfile(prev => readOnlyProfile || prev);
  }, [readOnlyProfile]);

  useEffect(() => {
    const { data } = patchData || {};

    if (profile && data) {
      if (data.checksum === profile.checksum && errors && errors?.length < 1) {
        setActiveForm(ProfileForm.None);
      } else if (data.checksum !== profile.checksum) {
        const diff = Object.keys(profile).reduce((accum: string[], attribute: string) => {
          if (profile[attribute] !== data[attribute]) {
            const indicatorStringIndex = attribute.indexOf('NonUSIndicator');

            if (indicatorStringIndex > -1) {
              const phoneFieldName = attribute.substr(0, indicatorStringIndex);
              return [...accum, attribute, phoneFieldName];
            }

            return [...accum, attribute];
          }

          return [...accum];
        }, []);

        setProfileDiff(diff);
        setActiveForm(ProfileForm.None);
      }
      setProfile({ ...(data as Profile), index: (profile.index || 0) + 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchData?.data?.checksum]);

  useEffect(() => {
    if (profileDiff.length) {
      setTimeout(() => {
        setProfileDiff([]);
      }, 4000);
    }
  }, [profileDiff]);

  if (!profile) return null;

  const patchProfile = (params: Profile) => {
    const { id } = params;
    const attributes: Profile = {
      ...profile,
      ...params,
      checksum: profile.checksum
    };
    const payload = {
      data: {
        id,
        type: 'profiles',
        attributes
      }
    };

    executePatch('/profile', payload);
  };

  const {
    legalName,
    email,
    mobilePhone,
    mobilePhoneNonUSIndicator,
    homePhone,
    homePhoneNonUSIndicator,
    workPhone,
    workPhoneNonUSIndicator,
    street1,
    street2,
    street3,
    street4,
    city,
    state,
    postalCode1,
    countryCode,
    maritalStatus
  } = profile;

  const {
    attributes: { displayValue: maritalStatusDisplayValue }
  } = maritalStatusCodes.find(({ id }) => id === maritalStatus) || { attributes: {} };

  const deactivateForms = () => {
    setActiveForm(ProfileForm.None);
  };

  const attributeUpdated = (attribute: string) => {
    return profileDiff.includes(attribute);
  };

  return (
    <>
      <Paper elevation={2} classes={{ root: classes.paper }}>
        <Typography component="h1" variant="h4">
          {t('home.subheading')}
        </Typography>
        <Typography component="p" variant="caption" classes={{ root: classes.caption }}>
          <Trans
            i18nKey="common:home.finePrint"
            components={[
              <a href={`${window.SERVICE_DOMAIN}/apps/customerinfo/member/preference.request`}>Preference Center</a>
            ]}
            values={{ preferenceCenterLink: 'Preference Center' }}
            defaults="Updates to this information are applied to your record maintained by Thrivent and its affiliates or
            subsidiaries, except Thrivent Credit Union and Thrivent Trust Company. They do not impact your
            <0>preferenceCenterLink</0> choices."
          />
        </Typography>

        <Divider />

        <ProfileInformationForm
          heading={t('generalInformation.heading')}
          tooltip={
            <Trans
              i18nKey="common:generalInformation.tooltip"
              defaults="General information"
              components={{ paragraph: <p />, bold: <strong /> }}
            />
          }
          formName={ProfileForm.General}
          activeForm={activeForm}
          setActiveForm={() => setActiveForm(prev => (prev ? ProfileForm.None : ProfileForm.General))}
          definitionTerms={
            <>
              <DefinitionTerm term={t('labels.legalName')} updated={attributeUpdated('legalName')}>
                {t('generalInformation.legalName', { name: legalName })}
              </DefinitionTerm>
              <DefinitionTerm term={t('labels.maritalStatus')} updated={attributeUpdated('maritalStatus')}>
                {maritalStatusDisplayValue}
              </DefinitionTerm>
            </>
          }
          profileForm={
            <GeneralInformationForm
              loading={loading}
              profile={profile}
              patchProfile={patchProfile}
              closeFormHandler={deactivateForms}
            />
          }
        />

        <Divider />

        <ProfileInformationForm
          heading={t('contactInformation.heading')}
          tooltip={
            <Trans
              i18nKey="common:contactInformation.tooltip"
              defaults="Contact information"
              components={{
                paragraph: <p />,
                bold: <strong />,
                privacyLink: (
                  <a title="Privacy Policy" href="https://www.thrivent.com/privacy-and-security/">
                    Privacy Policy
                  </a>
                )
              }}
            />
          }
          formName={ProfileForm.Contact}
          activeForm={activeForm}
          setActiveForm={() => setActiveForm(prev => (prev ? ProfileForm.None : ProfileForm.Contact))}
          definitionTerms={
            <>
              <DefinitionTerm term={t('labels.email')} updated={attributeUpdated('email')}>
                {email}
              </DefinitionTerm>
              <DefinitionTerm term={t('labels.phone', { context: 'mobile' })} updated={attributeUpdated('mobilePhone')}>
                {mobilePhone && t('phone', { context: mobilePhoneNonUSIndicator ? null : 'US', value: mobilePhone })}
              </DefinitionTerm>
              <DefinitionTerm term={t('labels.phone', { context: 'home' })} updated={attributeUpdated('homePhone')}>
                {homePhone && t('phone', { context: homePhoneNonUSIndicator ? null : 'US', value: homePhone })}
              </DefinitionTerm>
              <DefinitionTerm term={t('labels.phone', { context: 'work' })} updated={attributeUpdated('workPhone')}>
                {workPhone && t('phone', { context: workPhoneNonUSIndicator ? null : 'US', value: workPhone })}
              </DefinitionTerm>
            </>
          }
          profileForm={
            <ContactInformationForm
              loading={loading}
              profile={profile}
              patchProfile={patchProfile}
              closeFormHandler={deactivateForms}
            />
          }
        />

        <Divider />

        <ProfileInformationForm
          heading={t('addressInformation.heading')}
          tooltip={
            <Trans
              i18nKey="common:addressInformation.tooltip"
              defaults="Address information"
              components={{ paragraph: <p />, bold: <strong /> }}
            />
          }
          formName={ProfileForm.Address}
          activeForm={activeForm}
          setActiveForm={() => setActiveForm(prev => (prev ? ProfileForm.None : ProfileForm.Address))}
          definitionTerms={
            <>
              <DefinitionTerm
                term={t('labels.address')}
                updated={
                  attributeUpdated('street1') ||
                  attributeUpdated('street2') ||
                  attributeUpdated('street3') ||
                  attributeUpdated('street4') ||
                  attributeUpdated('city') ||
                  attributeUpdated('state') ||
                  attributeUpdated('countryCode') ||
                  attributeUpdated('postalCode1') ||
                  attributeUpdated('postalCode2')
                }
              >
                {countryCode === AddressType.US && (
                  <>
                    {t('addressInformation.street', { context: street2 ? 'multiline' : undefined, street1, street2 })}
                    <br />
                    {t('addressInformation.region', { city, state, postalCode: postalCode1 })}
                    <br />
                    {t('addressInformation.countryCode', { countryCode })}
                  </>
                )}
                {countryCode !== AddressType.US && (
                  <>
                    {street1}
                    <br />
                    {street2}
                    <br />
                    {street3}
                    <br />
                    {street4}
                    <br />
                    {t('addressInformation.countryCode', { countryCode })}
                  </>
                )}
              </DefinitionTerm>
            </>
          }
          profileForm={
            <AddressInformationForm
              loading={loading}
              profile={profile}
              patchProfile={patchProfile}
              closeFormHandler={deactivateForms}
            />
          }
        />

        <Divider />

        <Box display="block" margin="1rem 0 0">
          <Typography variant="caption">
            <Trans i18nKey="common:home.finePrintAdditionalChanges">
              To make changes to personal information not shown on this page, contact your financial professional or
              call 800-847-4836.
            </Trans>
          </Typography>
        </Box>
      </Paper>

      <RelatedLinkCards />

      <InvalidAddressModal open={hasAddressError} dismiss={() => setHasAddressError(false)} />
    </>
  );
};

export default withStyles(styles)(memo(ProfileInformation));
