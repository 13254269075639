import React from 'react';
import { Box } from '@material-ui/core';

interface Props {}

const DefinitionList: React.FC<Props> = ({ children }) => {
  return (
    <Box display="flex" flexDirection="column">
      {children}
    </Box>
  );
};

export default DefinitionList;
