import React, { memo } from 'react';
import { Divider as MuiDivider, withStyles, createStyles, WithStyles } from '@material-ui/core';

const styles = () => {
  return createStyles({
    divider: {
      height: '2px'
    }
  });
};

interface Props extends WithStyles<typeof styles> {}

const Divider: React.FC<Props> = ({ classes }) => {
  return <MuiDivider classes={{ root: classes.divider }} />;
};

export default withStyles(styles)(memo(Divider));
