/* eslint-disable react/jsx-wrap-multilines */
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Hidden, Paper, Box, Link, Typography, WithStyles, withStyles, createStyles, Theme } from '@material-ui/core';
import BankingHandBanking from '../../assets/BankingHandBanking.svg';
import Settings from '../../assets/siderail/settings.svg';
import Divider from '../Divider';

const styles = ({
  palette: {
    blue: { dark: blueDark }
  },
  breakpoints,
  spacing
}: Theme) =>
  createStyles({
    caption: {
      margin: spacing(1, 0, 2)
    },

    paper: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      margin: 0,
      width: '30%',

      [breakpoints.down('sm')]: {
        width: '100%',
        marginTop: '2em'
      }
    },

    paperLink: {
      [breakpoints.down('sm')]: {
        width: '100%'
      },
      textDecoration: 'none !important'
    },

    viewLink: {
      'a:hover &': {
        color: `${blueDark}`
      }
    },

    mobilePaper: {
      width: '100%',
      padding: '1.5rem'
    },

    mobileHeading: {
      textTransform: 'uppercase',
      marginBottom: '1rem'
    },

    mobileLink: {
      marginTop: '1rem',
      marginBottom: '1.5rem',

      '&:last-child': {
        marginBottom: 0
      }
    },

    mobileIcon: {
      marginRight: '1rem'
    }
  });

interface Props extends WithStyles<typeof styles> {}

const RelatedLinkCards: React.FC<Props> = ({ classes }) => {
  const { t } = useTranslation();
  const cards = [
    {
      id: 3,
      heading: t('relatedLinks:three.heading'),
      caption: t('relatedLinks:three.caption'),
      image: BankingHandBanking,
      icon: Settings,
      uri: `${window.SERVICE_DOMAIN}/apps/customerinfo/member/preference.request`
    }
  ];

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      justifyContent="space-between"
      alignItems="center"
      margin={{ xs: '1rem 1rem 0 1rem', md: '1.5rem 0 0 0' }}
    >
      <Hidden mdUp>
        <Paper elevation={2} classes={{ root: classes.mobilePaper }}>
          <Box display="flex" flexDirection="column">
            <Typography component="h3" variant="h5" classes={{ root: classes.mobileHeading }}>
              {t('relatedLinks:mobileHeading')}
            </Typography>

            <Divider />

            {cards.map(({ id, heading, icon, uri }) => (
              <Link key={id} href={uri} classes={{ root: classes.mobileLink }}>
                <Box display="flex" justifyContent="flex-start" alignItems="center">
                  <img src={icon} alt={heading} width={50} height={16} className={classes.mobileIcon} />
                  <Typography variant="body1">{heading}</Typography>
                </Box>
              </Link>
            ))}
          </Box>
        </Paper>
      </Hidden>

      <Hidden smDown>
        {cards.map(({ id, heading, caption, image, uri }) => (
          <Paper key={id} elevation={2} classes={{ root: classes.paper }}>
            <Link href={uri} classes={{ root: classes.paperLink }}>
              <Box padding="1.5rem" display="flex" justifyContent="center" flexDirection="column" height={264}>
                <Box display="flex" flexDirection="column" flex={3}>
                  <Box display="flex" justifyContent="center" marginBottom="1.5rem">
                    <img src={image} alt={heading} width={74} height={74} />
                  </Box>
                  <Typography component="h3" variant="h5" align="center">
                    {heading}
                  </Typography>
                  <Typography component="p" variant="caption" classes={{ root: classes.caption }} align="center">
                    {caption}
                  </Typography>
                </Box>

                <Box display="flex" flex={1} alignItems="flex-end" justifyContent="center">
                  <Typography
                    component="p"
                    variant="subtitle2"
                    color="secondary"
                    align="center"
                    classes={{ root: classes.viewLink }}
                  >
                    {t('common:links.view')}
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Paper>
        ))}
      </Hidden>
    </Box>
  );
};

export default withStyles(styles)(memo(RelatedLinkCards));
