import React, { useState, useEffect, memo } from 'react';
import {
  Box,
  Link,
  Paper,
  Grid,
  Container,
  Typography,
  withStyles,
  createStyles,
  WithStyles,
  Theme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useProfile from '../../hooks/useProfile';
import Envelope from '../../assets/siderail/envelope.svg';
import Help from '../../assets/siderail/help.svg';
import { Profile } from '../../types';
import ArrowRight from '../../assets/ArrowRight.svg';
import Divider from '../Divider';

const styles = ({
  breakpoints,
  palette: {
    blue: { main: blueMain }
  }
}: Theme) => {
  return createStyles({
    wrapper: {
      width: '100%'
    },

    paper: {
      padding: '1.25rem',
      margin: '1rem 0 0 0',

      [breakpoints.up('md')]: {
        padding: '1.5rem',
        margin: '0 0 1.5rem'
      }
    },

    heading: {
      marginBottom: '1.25rem',

      [breakpoints.up('md')]: {
        marginBottom: '1.5rem'
      }
    },

    priorityCopy: {
      marginTop: '1rem',
      marginBottom: '1rem',

      [breakpoints.up('md')]: {
        marginTop: '1.5rem',
        marginBottom: '1.5rem'
      }
    },

    link: {
      color: blueMain,

      '& img': {
        transition: 'all 0.3s ease'
      },

      '&:hover img': {
        transform: 'translateX(10px)'
      }
    },

    arrow: {
      verticalAlign: 'text-bottom',
      marginLeft: '0.5rem'
    }
  });
};

interface Props extends WithStyles<typeof styles> {}

const SideRail: React.FC<Props> = ({ classes }) => {
  const { t } = useTranslation();
  const profileData = useProfile();
  const [profile, setProfile] = useState<Profile | null>(null);
  const { legalName, id } = profile || {};

  useEffect(() => {
    setProfile(prev => profileData || prev);
  }, [profileData]);

  return (
    <section className={classes.wrapper}>
      <Container disableGutters>
        <Paper elevation={2} classes={{ root: classes.paper }}>
          <Typography variant="subtitle1" color="textPrimary" component="h3" classes={{ root: classes.heading }}>
            {t('sideRail:headings.sectionOne')}
          </Typography>

          <Divider />

          {legalName && id && (
            <>
              <Typography variant="body2" classes={{ root: classes.priorityCopy }}>
                <strong>{legalName}</strong>
                <br />
                {t('sideRail:id', { value: id })}
              </Typography>

              <Link href={`${window.SERVICE_DOMAIN}/portal/mythrivent`} component="a" classes={{ root: classes.link }}>
                {t('sideRail:goto')}
                <img src={ArrowRight} alt={t('common:links.myThrivent')} className={classes.arrow} />
              </Link>
            </>
          )}
        </Paper>

        <Paper elevation={2} classes={{ root: classes.paper }}>
          <Typography variant="subtitle1" color="textPrimary" component="h3" classes={{ root: classes.heading }}>
            {t('sideRail:headings.sectionTwo')}
          </Typography>

          <Divider />

          <Typography variant="h6" component="h4" classes={{ root: classes.priorityCopy }}>
            {t('sideRail:headings.memberCare')}
          </Typography>
          <Typography variant="body2">{t('sideRail:phone')}</Typography>
          <Typography variant="body2">{t('sideRail:hours')}</Typography>

          <Box marginBottom="1rem" marginTop="1rem">
            <Grid container>
              <Grid item xs={1}>
                <Box paddingTop="0.3em">
                  <img src={Envelope} alt={t('sideRail:envelope')} width="24" height="24" />
                </Box>
              </Grid>
              <Grid item xs={11}>
                <Box pt={1} pl={1}>
                  <Link
                    href="https://reader.thrivent.com/compose.aspx?to=BoxSecureMessage@thrivent.com"
                    variant="body2"
                    color="textPrimary"
                  >
                    {t('sideRail:sendEmailLink')}
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Divider />

          <Box marginTop="1.25rem">
            <Grid container>
              <Grid item xs={1}>
                <Box paddingTop="0.3em">
                  <img src={Help} alt={t('sideRail:help')} width="24" height="24" />
                </Box>
              </Grid>
              <Grid item xs={11}>
                <Box pt={1} pl={1}>
                  <Link href={`${window.WWW_DOMAIN}/faqs/#MyThrivent`} variant="body2" color="textPrimary">
                    {t('sideRail:faqsLink')}
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </section>
  );
};

export default withStyles(styles)(memo(SideRail));
