import React from 'react';
import { ThriventTextField } from 'thrivent-ui-library';
import InputMask from 'react-input-mask';
import HelperText from '../HelperText';
import ErrorIndicator from '../ErrorIndicator';

interface Props {
  textFieldClasses: {};
  id: string;
  name: string;
  label: string;
  handleChange: (evt: any) => void;
  value: string | undefined;
  error: string | undefined;
  enableMask: boolean;
}

const PhoneInput: React.FC<Props> = ({ textFieldClasses, id, name, label, handleChange, value, error, enableMask }) => {
  return (
    <>
      <InputMask
        mask={enableMask ? '999-999-9999' : ''}
        alwaysShowMask={enableMask}
        value={value || ''}
        onChange={handleChange}
        maskPlaceholder={enableMask ? ' ' : undefined}
      >
        <ThriventTextField
          classes={textFieldClasses}
          fullWidth
          id={id}
          name={name}
          label={label}
          InputProps={{
            error: !!error,
            endAdornment: <ErrorIndicator show={!!error} />
          }}
          helperText={<HelperText show={!!error} text={error} />}
        />
      </InputMask>
    </>
  );
};

export default PhoneInput;
