// import { usePublicGet } from 'thrivent-ui-library';
import { useGet } from 'thrivent-ui-library';
import { ApiError, Profile, JsonResponse } from '../types';

const useProfile = () => {
  const { data } = useGet<JsonResponse<Profile>, ApiError>('/profile');
  return data?.data;
};

export default useProfile;
