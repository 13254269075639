import React, { memo } from 'react';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Typography, Box, MenuItem, createStyles, withStyles, WithStyles, Theme } from '@material-ui/core';
import { ThriventSelect, ThriventTextField } from 'thrivent-ui-library';
import clsx from 'clsx';
import { Profile } from '../../types';
import FormButtons from '../FormButtons';
import HelperText from '../HelperText';
import useProfileValues from '../../hooks/useProfileValues';
import ErrorIndicator from '../ErrorIndicator';

const styles = ({ breakpoints, palette: { errorRed } }: Theme) => {
  return createStyles({
    inputWidth: {
      width: '100%',

      [breakpoints.up('md')]: {
        width: '342px'
      }
    },

    inputHeight: {
      height: '50px'
    },

    input: {
      marginBottom: '1rem',

      [breakpoints.up('md')]: {
        marginBottom: '1.125rem'
      },

      '& .Mui-error': {
        borderColor: errorRed
      }
    },

    select: {
      width: '100%',

      [breakpoints.up('sm')]: {
        width: '242px'
      }
    },

    selectInput: {
      paddingTop: '13px',
      paddingBottom: '13px',
      width: '100%',
      height: '24px',

      [breakpoints.up('sm')]: {
        width: '218px'
      }
    }
  });
};

interface Props extends WithStyles<typeof styles> {
  profile: Profile;
  patchProfile: (data: Profile) => void;
  closeFormHandler: () => void;
  loading: boolean;
}

const textOnlyRegex = /^[a-zA-Z]+[\sa-zA-Z]*$/;

const cleanDataFromForm = (data: Profile) => {
  return { ...data, lastName: data.lastName.trim() };
};

const ProfileForm: React.FC<Props> = ({ classes, closeFormHandler, loading, profile, patchProfile }) => {
  const { t } = useTranslation('common');
  const { legalName } = profile;
  const { maritalStatusCodes } = useProfileValues();
  const yupSchema = Yup.object().shape({
    lastName: Yup.string()
      .required(t('validation:required'))
      .matches(textOnlyRegex, t('validation:textOnly'))
      .max(40, t('validation:Max length is', { stringLength: 40 })),
    maritalStatus: Yup.string().required()
  });

  return (
    <Formik
      enableReinitialize
      initialValues={profile}
      validationSchema={yupSchema}
      onSubmit={(data: Profile, actions) => {
        const cleanedData: Profile = cleanDataFromForm(data);
        patchProfile(cleanedData);
        actions.setSubmitting(false);
      }}
    >
      {({ values, errors, handleChange, handleSubmit, handleReset, isValid, dirty }: FormikProps<Profile>) => {
        const resetForm = () => {
          closeFormHandler();
          handleReset();
        };

        return (
          <>
            <Box width={{ xs: '100%', md: '32rem' }}>
              <Box marginBottom="2.5rem">
                <Box marginBottom="1rem">
                  <Typography component="p" variant="overline">
                    {t('labels.legalName')}
                  </Typography>
                </Box>
                <Typography component="p" variant="body2">
                  {t('generalInformation.legalName', { name: legalName })}
                </Typography>
              </Box>

              <ThriventTextField
                onChange={handleChange}
                fullWidth
                id="lastName"
                name="lastName"
                value={values.lastName}
                label={t('labels.lastName')}
                required
                classes={{ root: clsx(classes.input) }}
                InputProps={{
                  error: !!errors?.lastName,
                  className: clsx(classes.inputWidth, classes.inputHeight),
                  endAdornment: <ErrorIndicator show={!!errors?.lastName} />
                }}
                helperText={<HelperText show={!!errors?.lastName} text={errors?.lastName} />}
              />

              <ThriventSelect
                formControlProps={{
                  required: true,
                  classes: { root: clsx(classes.input, classes.select) }
                }}
                label={t('labels.maritalStatus')}
                value={values.maritalStatus || ''}
                id="maritalStatus"
                selectProps={{
                  classes: { selectMenu: clsx(classes.select, classes.inputHeight, classes.selectInput) },
                  onChange: handleChange,
                  name: 'maritalStatus',
                  'data-test-id': 'marital-status'
                }}
              >
                {maritalStatusCodes.map(code => {
                  const {
                    id,
                    attributes: { displayValue }
                  } = code;

                  return (
                    <MenuItem key={id} value={id}>
                      {displayValue}
                    </MenuItem>
                  );
                })}
              </ThriventSelect>
              <HelperText show={!!errors?.maritalStatus} text={t('helperText.required')} />
            </Box>

            <Box marginTop={{ xs: '3rem', md: '2.625rem' }}>
              <FormButtons
                onCancel={resetForm}
                onSubmit={handleSubmit}
                loading={loading}
                isValid={isValid}
                dirty={dirty}
              />
            </Box>
          </>
        );
      }}
    </Formik>
  );
};

export default withStyles(styles)(memo(ProfileForm));
