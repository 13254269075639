import React, { memo } from 'react';
import { FormHelperText } from '@material-ui/core';

interface Props {
  text?: string;
  show: boolean;
}

const HelperText: React.FC<Props> = ({ text, show }) => {
  return (
    <FormHelperText component="span" error>
      {show ? text : ' '}
    </FormHelperText>
  );
};

export default memo(HelperText);
