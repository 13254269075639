import React, { memo } from 'react';
import { createStyles, Hidden, withStyles, WithStyles, Box, Link, Typography, Theme } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import BankingGroup from '../../assets/BankingGroup.svg';
import BankingHandBanking from '../../assets/BankingHandBanking.svg';
import MembershipNetworkCircles from '../../assets/MembershipNetworkCircles.svg';

const styles = ({
  breakpoints,
  palette: {
    blue: { main: blueMain, dark: blueDark }
  }
}: Theme) => {
  return createStyles({
    viewLink: {
      textAlign: 'center',
      color: `${blueMain}`,

      'a:hover &': {
        color: `${blueDark}`
      }
    },

    link: {
      display: 'flex',

      '&:hover': {
        textDecoration: 'none'
      },

      [breakpoints.up('md')]: {
        padding: 0
      }
    },

    text: {
      fontSize: '1rem',
      fontWeight: 'bold',

      [breakpoints.up('md')]: {
        fontSize: '1.25rem'
      }
    },

    subtext: {
      lineHeight: '1.5rem',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',

      '& svg': {
        verticalAlign: 'middle',
        color: blueMain
      }
    },

    image: {
      width: 48,

      [breakpoints.up('md')]: {
        width: 90
      }
    }
  });
};

export enum Topic {
  financialProfile = 'financialProfile',
  personalInformation = 'personalInformation',
  communication = 'communication'
}

const imageForTopic = {
  [Topic.financialProfile]: BankingGroup,
  [Topic.personalInformation]: MembershipNetworkCircles,
  [Topic.communication]: BankingHandBanking
};

const urlForTopic = {
  [Topic.financialProfile]: `${window.SERVICE_DOMAIN}/apps/SuitabilityClientWeb/profile/suitabilityEntryPage.do`,
  [Topic.personalInformation]: '/manage-my-profile/personal-information',
  [Topic.communication]: `${window.SERVICE_DOMAIN}/apps/customerinfo/member/preference.request`
};

interface Props extends WithStyles<typeof styles> {
  text: string;
  subtext: string;
  topicName: Topic;
}

const ManageMyProfileLink: React.FC<Props> = ({ classes, text, subtext, topicName }) => {
  const { t } = useTranslation('manageMyProfile');

  return (
    <Link href={urlForTopic[topicName]} classes={{ root: classes.link }}>
      <Box display="flex" width={{ xs: '100%', md: '90%' }} justifyContent="flex-start" alignItems="center">
        <Box display="block" height={{ xs: 48, md: 90 }} width={{ xs: 48, md: 90 }}>
          <img src={imageForTopic[topicName]} className={classes.image} alt={t(topicName)} />
        </Box>

        <Box display="block" paddingLeft={{ xs: '1.5rem', md: '2rem' }}>
          <Typography gutterBottom component="p" variant="h5" classes={{ root: classes.text }}>
            {text}
          </Typography>
          <Typography component="p" variant="body2" color="textPrimary" classes={{ root: classes.subtext }}>
            {subtext}
            <Hidden mdUp>
              <ArrowForward />
            </Hidden>
          </Typography>
        </Box>
      </Box>

      <Hidden smDown>
        <Box display="flex" marginLeft="2rem" justifyContent="center" alignItems="center">
          <Typography component="span" display="block" variant="subtitle2" classes={{ root: classes.viewLink }}>
            {t('view')}
          </Typography>
        </Box>
      </Hidden>
    </Link>
  );
};

export default withStyles(styles)(memo(ManageMyProfileLink));
