// import { usePublicGet } from 'thrivent-ui-library';
import { useGet } from 'thrivent-ui-library';
import { ApiError, ProfileValue, ProfileValueType, JsonResponse } from '../types';

const useProfileValues = () => {
  const { data } = useGet<JsonResponse<ProfileValue[]>, ApiError>('/profile/values');

  const profileValues = data?.data || [];

  const countryCodes = profileValues.filter(({ type }) => type === ProfileValueType.COUNTRY_CODE);
  const stateCodes = profileValues.filter(({ type }) => type === ProfileValueType.STATE_CODE);
  const maritalStatusCodes = profileValues.filter(({ type }) => type === ProfileValueType.MARITAL_STATUS_CODE);

  return { countryCodes, stateCodes, maritalStatusCodes };
};

export default useProfileValues;
