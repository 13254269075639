import React, { memo } from 'react';
import { Box, Typography, createStyles, WithStyles, withStyles, Theme, Fade } from '@material-ui/core';
import Checkmark from '../../assets/Checkmark.svg';

const styles = ({
  palette: {
    gray: { main }
  }
}: Theme) =>
  createStyles({
    term: {
      color: main,

      '&:after': {
        content: '":"'
      }
    },
    attribute: {
      '& .MuiTypography-root': {
        transition: 'color 1s linear'
      }
    },
    updatedAttribute: {
      '& .MuiTypography-root': {
        color: 'green'
      }
    }
  });

interface Props extends WithStyles<typeof styles> {
  term: string;
  updated?: boolean;
}

const DefinitionTerm: React.FC<Props> = ({ classes, children, term, updated }) => {
  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      className={`${classes.attribute} ${updated ? classes.updatedAttribute : ''}`}
      marginBottom={{ xs: '1rem', md: '1.5rem' }}
    >
      <Box flex={1}>
        <Typography component="p" variant="body1" classes={{ root: classes.term }}>
          {term}
        </Typography>
      </Box>

      <Box flex={2} display="flex">
        <Typography component="p" variant="body1" aria-describedby="checkmark">
          {children}
        </Typography>
        <Fade in={updated} timeout={{ enter: 1000, exit: 1000 }}>
          <Box ml={2} display="flex" alignItems="center">
            <img src={Checkmark} id="checkmark" alt={`The value for ${term} has been successfully updated.`} />
          </Box>
        </Fade>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(memo(DefinitionTerm));
