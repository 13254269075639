/* eslint-disable react/no-this-in-sfc */
/* eslint-disable @typescript-eslint/space-before-function-paren */
/* eslint-disable func-names */
import React, { memo } from 'react';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { createStyles, Box, withStyles, WithStyles, Theme } from '@material-ui/core';
import { ThriventTextField } from 'thrivent-ui-library';
import PhoneField from '../PhoneField';
import { Profile } from '../../types';
import FormButtons from '../FormButtons';
import DuplicatePhoneModal from '../DuplicatePhoneModal';
import ErrorIndicator from '../ErrorIndicator';
import HelperText from '../HelperText';

const styles = ({ breakpoints, palette: { errorRed } }: Theme) =>
  createStyles({
    textField: {
      marginBottom: '1rem',

      [breakpoints.up('md')]: {
        marginBottom: '1.125rem'
      },

      '& .Mui-error': {
        borderColor: errorRed
      }
    }
  });

interface Props extends WithStyles<typeof styles> {
  profile: Profile;
  patchProfile: (data: Profile) => void;
  closeFormHandler: () => void;
  loading: boolean;
}

const phoneRegex = /^(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)$/;
const emailRegex = /.+@.+\..+/;

declare module 'yup' {
  interface StringSchema {
    unique(keys: string[]): StringSchema;
  }
}

const ContactInformationForm: React.FC<Props> = ({ classes, closeFormHandler, loading, profile, patchProfile }) => {
  const { t } = useTranslation();

  Yup.addMethod(Yup.string, 'unique', function(keys: string[]) {
    const thizz = this as Yup.StringSchema;
    return thizz.test('unique', t('validation:unique'), function(value: string) {
      let retVal = true;
      keys.forEach(k => {
        const valToCompare = this.parent[k];
        if (valToCompare && valToCompare === value) {
          retVal = false;
        }
      });

      return retVal;
    });
  });

  const yupSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('validation:required'))
      .matches(emailRegex, t('validation:formatting')),
    mobilePhoneNonUSIndicator: Yup.string(),
    mobilePhone: Yup.string()
      .when('mobilePhoneNonUSIndicator', {
        is: 'false',
        then: (field: any) => field.matches(phoneRegex, t('validation:formatting'))
      })
      .notRequired()
      .unique(['workPhone', 'homePhone']),
    homePhoneNonUSIndicator: Yup.string(),
    homePhone: Yup.string()
      .when('homePhoneNonUSIndicator', {
        is: 'false',
        then: (field: any) => field.matches(phoneRegex, t('validation:formatting'))
      })
      .unique(['workPhone', 'mobilePhone']),
    workPhoneNonUSIndicator: Yup.string(),
    workPhone: Yup.string()
      .when('workPhoneNonUSIndicator', {
        is: 'false',
        then: (field: any) => field.matches(phoneRegex, t('validation:formatting'))
      })
      .unique(['homePhone', 'mobilePhone'])
  });

  return (
    <Formik
      enableReinitialize
      initialValues={profile}
      validationSchema={yupSchema}
      onSubmit={(data, actions) => {
        const { mobilePhoneNonUSIndicator, homePhoneNonUSIndicator, workPhoneNonUSIndicator } = data;

        patchProfile({
          ...data,
          mobilePhoneNonUSIndicator: mobilePhoneNonUSIndicator.toString() === 'true',
          homePhoneNonUSIndicator: homePhoneNonUSIndicator.toString() === 'true',
          workPhoneNonUSIndicator: workPhoneNonUSIndicator.toString() === 'true'
        });

        actions.setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        handleReset,
        setFieldValue,
        setTouched,
        isValid,
        dirty
      }: FormikProps<Profile>) => {
        const resetForm = () => {
          handleReset();
          closeFormHandler();
        };

        const handleBooleanFieldChange = (evt: any) => {
          const nonUSPhoneIndicator = evt.target.value === 'true';
          const { name } = evt.target;
          const phoneFieldName = name.substr(0, name.indexOf('NonUSIndicator'));

          setFieldValue(evt.target.name, nonUSPhoneIndicator);
          setTouched({ fields: { [phoneFieldName]: true } });

          // NOTE: If the phone type changes from international to US, remove all non-digit characters so the phone
          // input mask is applied correctly.
          if (!nonUSPhoneIndicator) {
            const phoneFieldValue = values[phoneFieldName];
            const formattedPhoneFieldValue = phoneFieldValue.replace(/[^\d]/gi, '').substr(0, 10);

            setFieldValue(phoneFieldName, formattedPhoneFieldValue);
          }
        };

        const handlePhoneFieldChange = (evt: any) => {
          const { name, value } = evt.target;
          const phoneFieldType = values[`${name}NonUSIndicator`];
          const phoneFieldValue = phoneFieldType ? value : value.replace(/[^\d]/gi, '').substr(0, 10);

          setFieldValue(evt.target.name, phoneFieldValue);
        };

        return (
          <>
            <Box width={{ xs: '100%', md: '21.375rem' }}>
              <ThriventTextField
                onChange={handleChange}
                fullWidth
                id="email"
                name="email"
                value={values.email}
                label={t('common:labels.email')}
                required
                classes={{ root: classes.textField }}
                InputProps={{
                  error: !!errors?.email,
                  endAdornment: <ErrorIndicator show={!!errors?.email} />
                }}
                helperText={<HelperText show={!!errors?.email} text={errors?.email} />}
              />
            </Box>

            <PhoneField
              type="mobile"
              phoneValue={values.mobilePhone}
              nonUSIndicatorValue={values.mobilePhoneNonUSIndicator?.toString()}
              onTypeChange={handleBooleanFieldChange}
              onChange={handlePhoneFieldChange}
              error={errors?.mobilePhone}
            />

            <PhoneField
              type="home"
              phoneValue={values.homePhone}
              nonUSIndicatorValue={values.homePhoneNonUSIndicator?.toString()}
              onTypeChange={handleBooleanFieldChange}
              onChange={handlePhoneFieldChange}
              error={errors?.homePhone}
            />

            <PhoneField
              type="work"
              phoneValue={values.workPhone}
              nonUSIndicatorValue={values.workPhoneNonUSIndicator?.toString()}
              onTypeChange={handleBooleanFieldChange}
              onChange={handlePhoneFieldChange}
              error={errors?.workPhone}
            />

            <Box marginTop={{ xs: '3rem', md: '1.875rem' }}>
              <FormButtons
                onCancel={resetForm}
                onSubmit={handleSubmit}
                loading={loading}
                isValid={isValid}
                dirty={dirty}
              />
            </Box>

            <DuplicatePhoneModal profile={profile} patchProfile={patchProfile} />
          </>
        );
      }}
    </Formik>
  );
};

export default withStyles(styles)(memo(ContactInformationForm));
