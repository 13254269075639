import React, { useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, Button, Box, Grid, Typography, withStyles, WithStyles, Theme } from '@material-ui/core';
import Conversation from '../../assets/Conversation.svg';
import { useHeader } from '../../hooks/useHeader';

const styles = ({ breakpoints }: Theme) =>
  createStyles({
    image: {
      maxWidth: 303,

      [breakpoints.up('md')]: {
        maxWidth: 484
      }
    },

    heading: {
      lineHeight: '3rem',
      textAlign: 'center',

      [breakpoints.up('md')]: {
        lineHeight: '4rem',
        textAlign: 'left'
      }
    },

    backLink: {
      fontSize: '0.875rem',
      padding: '0.75rem 0.875rem',
      margin: '0 2rem',
      width: 'fit-content',

      [breakpoints.up('md')]: {
        margin: 0
      }
    },

    centered: {
      textAlign: 'center',

      [breakpoints.up('md')]: {
        textAlign: 'left'
      }
    }
  });

interface Props extends WithStyles<typeof styles> {}
const ErrorScreen: React.FC<Props> = ({ classes }) => {
  const { t } = useTranslation();
  const { setPageHeading } = useHeader();

  useEffect(() => {
    setPageHeading(t('common:home.heading'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={5}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent={{ xs: 'center', md: 'flex-start' }}
          alignItems={{ xs: 'center', md: 'flex-start' }}
          marginTop={{ xs: '2rem', md: '3rem' }}
          marginBottom={{ xs: '2rem', sm: 0 }}
        >
          <Typography variant="h1" className={classes.heading}>
            {t('errorScreen:statement')}
          </Typography>
          <Box margin="1.5rem 0 2rem">
            <Typography variant="h5" className={classes.centered}>
              {t('errorScreen:instruction')}
            </Typography>
          </Box>
          <Button
            variant="contained"
            classes={{ root: classes.backLink }}
            href={`${window.SERVICE_DOMAIN}/portal/mythrivent`}
            color="default"
          >
            {t('common:links.backToMyThrivent')}
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12} md={7}>
        <Box display="flex" justifyContent="center" marginTop={{ xs: '4rem', md: 0 }}>
          <img src={Conversation} className={classes.image} alt="Conversation" />
        </Box>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(memo(ErrorScreen));
