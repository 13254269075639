import React, { memo } from 'react';
import { Box, Button, createStyles, withStyles, WithStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const styles = ({ breakpoints }: Theme) => {
  return createStyles({
    button: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '40px',
      padding: 0,

      [breakpoints.up('md')]: {
        height: '50px'
      }
    }
  });
};

interface Props extends WithStyles<typeof styles> {
  onCancel: () => void;
  onSubmit: () => void;
  isValid: boolean;
  loading: boolean;
  dirty: boolean;
}

const FormButtons: React.FC<Props> = ({ classes, onCancel, onSubmit, isValid, loading, dirty }) => {
  const { t } = useTranslation('common');

  return (
    <Box
      marginBottom={{ xs: '1rem', md: '1.5rem' }}
      display="flex"
      flexDirection="row"
      justifyContent={{ xs: 'space-between', md: 'flex-end' }}
    >
      <Box width={{ xs: '47%', md: '164px' }} marginRight={{ xs: 0, md: '1.5rem' }}>
        <Button fullWidth variant="outlined" onClick={onCancel} classes={{ root: classes.button }}>
          {t('buttons.cancel')}
        </Button>
      </Box>
      <Box width={{ xs: '45%', md: '164px' }}>
        <Button
          fullWidth
          disabled={!isValid || !dirty || loading}
          onClick={onSubmit}
          variant="contained"
          type="submit"
          classes={{ root: classes.button }}
        >
          {t('buttons.save')}
        </Button>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(memo(FormButtons));
