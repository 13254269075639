/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/indent */
import React, { memo } from 'react';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useTranslation, Trans } from 'react-i18next';
import clsx from 'clsx';
import {
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Theme,
  MenuItem,
  createStyles,
  withStyles,
  WithStyles
} from '@material-ui/core';
import { ThriventSelect, ThriventTextField } from 'thrivent-ui-library';
import { Profile, AddressType } from '../../types';
import FormButtons from '../FormButtons';
import useProfileValues from '../../hooks/useProfileValues';
import ErrorIndicator from '../ErrorIndicator';
import HelperText from '../HelperText';
import InfoTooltip from '../InfoTooltip/InfoTooltip';

const styles = ({ breakpoints, palette: { errorRed } }: Theme) =>
  createStyles({
    inputWidth: {
      width: '100%',

      [breakpoints.up('lg')]: {
        width: '446px'
      }
    },

    input: {
      marginBottom: '1rem',

      [breakpoints.up('md')]: {
        marginBottom: '1.125rem'
      },

      '& .Mui-error': {
        borderColor: errorRed
      }
    },

    select: {
      width: '100%'
    },

    selectMenu: {
      paddingTop: '13px',
      paddingBottom: '13px',
      width: '100%',
      height: '24px'
    },

    tooltipWrapper: {
      marginLeft: '4px',
      transform: 'translateY(-10%) scale(1.3)'
    },

    addressTypeLabel: {
      fontSize: '1rem'
    },

    radioButtonLabel: {
      fontSize: '0.875rem'
    }
  });

interface Props extends WithStyles<typeof styles> {
  profile: Profile;
  patchProfile: (data: Profile) => void;
  closeFormHandler: () => void;
  loading: boolean;
}

const textOnlyRegex = /^[\S]+[\s\S]*$/;
const postalCodeRegex = /^\d{5}$/;

const AddressInformationForm: React.FC<Props> = ({ classes, closeFormHandler, loading, profile, patchProfile }) => {
  const { t } = useTranslation();
  const { countryCodes, stateCodes } = useProfileValues();
  const yupSchema = Yup.object().shape({
    street1: Yup.string()
      .required(t('validation:required'))
      .matches(textOnlyRegex, t('validation:textOnly')),
    street2: Yup.string().when('countryCode', {
      is: AddressType.US,
      then: Yup.string(),
      otherwise: Yup.string().required(t('validation:required'))
    }),
    street3: Yup.string().when('countryCode', {
      is: AddressType.US,
      then: Yup.string(),
      otherwise: Yup.string().required(t('validation:required'))
    }),
    street4: Yup.string().when('countryCode', {
      is: AddressType.US,
      then: Yup.string(),
      otherwise: Yup.string().required(t('validation:required'))
    }),
    city: Yup.string().when('countryCode', {
      is: AddressType.US,
      then: Yup.string()
        .required(t('validation:required'))
        .matches(textOnlyRegex, t('validation:textOnly'))
    }),
    state: Yup.string().when('countryCode', {
      is: AddressType.US,
      then: Yup.string()
        .required(t('validation:required'))
        .matches(textOnlyRegex, t('validation:textOnly'))
    }),
    postalCode1: Yup.string().when('countryCode', {
      is: AddressType.US,
      then: Yup.string()
        .required(t('validation:required'))
        .matches(postalCodeRegex, t('validation:postalCodeFormat'))
    }),
    postalCode2: Yup.string(),
    countryCode: Yup.string().required(t('validation:required'))
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...profile,
        addressType: profile.countryCode === AddressType.US ? AddressType.US : AddressType.International
      }}
      validationSchema={yupSchema}
      onSubmit={(data: Profile, actions) => {
        const { countryCode } = data;
        const params =
          countryCode === AddressType.US
            ? {
                ...data,
                street3: '',
                street4: ''
              }
            : {
                ...data,
                city: '',
                state: '',
                postalCode1: ''
              };
        patchProfile(params);
        actions.setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        handleReset,
        setFieldValue,
        setFieldError,
        isValid,
        dirty,
        touched
      }: FormikProps<Profile>) => {
        const onAddressTypeChange = (evt: any) => {
          const addressTypeUS = evt.target.value === AddressType.US;

          setFieldValue('countryCode', addressTypeUS ? AddressType.US : '', false);
          setFieldError('countryCode', ' ');

          setFieldValue('addressType', evt.target.value, false);
        };
        const resetForm = () => {
          closeFormHandler();
          handleReset();
        };
        const isUSAddress = values.countryCode === AddressType.US;
        const StateLabel = (
          <Box position="relative" display="flex" flexDirection="row" justifyItems="flex-start" alignItems="flex-start">
            <FormLabel required>{t('common:labels.state', { context: values.countryCode })}</FormLabel>
            <Box className={classes.tooltipWrapper}>
              <InfoTooltip
                title={
                  <Trans
                    i18nKey="common:addressInformation.stateTooltip"
                    defaults="Address information"
                    components={{ paragraph: <p />, bold: <strong /> }}
                  />
                }
              />
            </Box>
          </Box>
        );

        return (
          <>
            <FormControl component="fieldset" className={classes.input}>
              <FormLabel component="legend" classes={{ root: classes.addressTypeLabel }}>
                {t('common:labels.addressType')}
              </FormLabel>
              <RadioGroup
                aria-label="address-type"
                name="addressType"
                value={values.addressType}
                onChange={onAddressTypeChange}
                row
              >
                <FormControlLabel
                  value={AddressType.US}
                  control={<Radio />}
                  classes={{ label: classes.radioButtonLabel }}
                  label={t('common:addressType', { context: AddressType.US })}
                />
                <FormControlLabel
                  value={AddressType.International}
                  control={<Radio />}
                  classes={{ label: classes.radioButtonLabel }}
                  label={t('common:addressType', { context: AddressType.International })}
                />
              </RadioGroup>
            </FormControl>

            {isUSAddress && (
              <>
                <Box width={{ xs: '100%', md: '27.875rem' }}>
                  <ThriventTextField
                    onChange={handleChange}
                    fullWidth
                    id="street1"
                    name="street1"
                    value={values.street1}
                    label={t('common:labels.street1', { context: values.countryCode })}
                    required
                    classes={{ root: clsx(classes.inputWidth, classes.input) }}
                    InputProps={{
                      error: !!errors?.street1,
                      endAdornment: <ErrorIndicator show={!!errors?.street1} />
                    }}
                    helperText={<HelperText show={!!errors?.street1} text={errors?.street1} />}
                  />

                  <ThriventTextField
                    onChange={handleChange}
                    fullWidth
                    id="street2"
                    name="street2"
                    value={values.street2}
                    label={t('common:labels.street2')}
                    classes={{ root: classes.input }}
                    InputProps={{
                      error: !!errors?.street2,
                      endAdornment: <ErrorIndicator show={!!errors?.street2} />
                    }}
                    helperText={<HelperText show={!!errors?.street2} text={errors?.street2} />}
                  />

                  <ThriventTextField
                    onChange={handleChange}
                    fullWidth
                    id="city"
                    name="city"
                    value={values.city}
                    label={t('common:labels.city')}
                    required
                    classes={{ root: classes.input }}
                    InputProps={{
                      error: !!errors?.city,
                      endAdornment: <ErrorIndicator show={!!errors?.city} />
                    }}
                    helperText={<HelperText show={!!errors?.city && !!touched?.city} text={errors?.city} />}
                  />
                </Box>

                <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} width="100%">
                  <Box width={{ xs: '100%', sm: '242px' }} marginRight="40px" display="flex" flexDirection="column">
                    <ThriventSelect
                      formControlProps={{
                        classes: { root: clsx(classes.input, classes.select) }
                      }}
                      label={StateLabel}
                      value={values.state}
                      id="state"
                      selectProps={{
                        classes: { root: classes.selectMenu },
                        onChange: handleChange,
                        name: 'state'
                      }}
                    >
                      {stateCodes.map(code => {
                        const {
                          id,
                          attributes: { displayValue }
                        } = code;

                        return (
                          <MenuItem key={id} value={id}>
                            {displayValue}
                          </MenuItem>
                        );
                      })}
                    </ThriventSelect>
                    <HelperText show={!!errors?.state && !!touched?.state} text={errors?.state} />
                  </Box>

                  <Box width={{ xs: '100%', sm: '242px' }} display="flex" flexDirection="column">
                    <ThriventTextField
                      onChange={handleChange}
                      fullWidth
                      id="postalCode1"
                      name="postalCode1"
                      value={values.postalCode1}
                      required
                      label={t('common:labels.postalCode1', { context: values.countryCode })}
                      classes={{ root: classes.input }}
                      InputProps={{
                        error: !!errors?.postalCode1,
                        endAdornment: <ErrorIndicator show={!!errors?.postalCode1} />
                      }}
                      helperText={<HelperText show={!!errors?.postalCode1} text={errors?.postalCode1} />}
                    />
                  </Box>
                </Box>
              </>
            )}

            {!isUSAddress && (
              <>
                <Box width={{ xs: '100%', md: '27.875rem' }}>
                  <ThriventTextField
                    onChange={handleChange}
                    fullWidth
                    id="street1"
                    name="street1"
                    value={values.street1}
                    label={t('common:labels.street1', { context: values.countryCode })}
                    required
                    classes={{ root: clsx(classes.inputWidth, classes.input) }}
                    InputProps={{
                      error: !!errors?.street1,
                      endAdornment: <ErrorIndicator show={!!errors?.street1} />
                    }}
                    helperText={<HelperText show={!!errors?.street1} text={errors?.street1} />}
                  />

                  <ThriventTextField
                    onChange={handleChange}
                    fullWidth
                    id="street2"
                    name="street2"
                    value={values.street2}
                    label={t('common:labels.city')}
                    required
                    classes={{ root: classes.input }}
                    InputProps={{
                      error: !!errors?.street2,
                      endAdornment: <ErrorIndicator show={!!errors?.street2} />
                    }}
                    helperText={<HelperText show={!!errors?.street2} text={errors?.street2} />}
                  />
                </Box>

                <Box width={{ xs: '100%', md: '27.875rem' }}>
                  <ThriventTextField
                    onChange={handleChange}
                    fullWidth
                    id="street3"
                    name="street3"
                    value={values.street3}
                    required
                    label={t('common:labels.state', { context: values.countryCode })}
                    classes={{ root: classes.input }}
                    InputProps={{
                      error: !!errors?.street3,
                      endAdornment: <ErrorIndicator show={!!errors?.street3} />
                    }}
                    helperText={<HelperText show={!!errors?.street3 && !!touched?.street3} text={errors?.street3} />}
                  />

                  <ThriventTextField
                    onChange={handleChange}
                    fullWidth
                    id="street4"
                    name="street4"
                    value={values.street4}
                    required
                    label={t('common:labels.postalCode1', { context: values.countryCode })}
                    classes={{ root: classes.input }}
                    InputProps={{
                      error: !!errors?.street4,
                      endAdornment: <ErrorIndicator show={!!errors?.street4} />
                    }}
                    helperText={<HelperText show={!!errors?.street4} text={errors?.street4} />}
                  />

                  <ThriventSelect
                    formControlProps={{ required: true, classes: { root: clsx(classes.input, classes.select) } }}
                    label={t('common:labels.country')}
                    value={values.countryCode}
                    id="countryCode"
                    selectProps={{
                      classes: { root: classes.selectMenu },
                      onChange: handleChange,
                      name: 'countryCode'
                    }}
                  >
                    {countryCodes.map(code => {
                      const {
                        id,
                        attributes: { displayValue }
                      } = code;

                      return (
                        <MenuItem key={id} value={id}>
                          {displayValue}
                        </MenuItem>
                      );
                    })}
                  </ThriventSelect>
                  <HelperText show={!!errors?.countryCode && !!touched?.countryCode} text={errors?.countryCode} />
                </Box>
              </>
            )}

            <Box marginTop={{ xs: '3rem', md: '0.75rem' }}>
              <FormButtons
                onCancel={resetForm}
                onSubmit={handleSubmit}
                loading={loading}
                isValid={isValid}
                dirty={dirty}
              />
            </Box>
          </>
        );
      }}
    </Formik>
  );
};

export default withStyles(styles)(memo(AddressInformationForm));
