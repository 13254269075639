export default {
  greeting: 'Welcome to $t(glossary:brand)',
  menuButton: 'Menu',
  home: {
    heading: 'Personal Information',
    subheading: 'Manage Your Personal Information',
    caption:
      'Providing your most up-to-date information allows $t(glossary:brand) to provide you with the best service possible.',
    finePrint:
      'Updates to this information are applied to your record maintained by Thrivent and its affiliates or subsidiaries, except Thrivent Credit Union and Thrivent Trust Company. They do not impact your <0>{{ preferenceCenterLink }}</0> choices.',
    findPrintAdditionalChanges:
      'To make changes to personal information not shown on this page, contact your financial professional or call 800-847-4836.'
  },
  generalInformation: {
    heading: 'General Information',
    tooltip: `<bold>Legal Name:</bold>
              <paragraph>Your full name as recognized in law. A legal name is usually acquired at birth or through a court order.</paragraph>
              <paragraph>Changes to your legal name can impact your products or accounts with Thrivent. If you make a change to your last name, you may receive a confirmation and an IRS Form W9 from Thrivent. To make changes other than your last name, please contact Member Care Services.</paragraph>`,
    legalName: '{{ name }}',
    lastName: '{{ name }}',
    maritalStatus: '{{ status }}'
  },
  contactInformation: {
    heading: 'Contact Information',
    tooltip: `<bold>Contact Information:</bold>
              <paragraph>To change this information for additional customers who are members of your household, or if you have questions about updating this information, please call Member Care Services.</paragraph>
              <bold>Email Address:</bold>
              <paragraph>Please ensure your email address is current and correct. Thrivent requires an up-to-date email for paperless delivery and other online features. Your email address will be used in accordance with our <privacyLink>Privacy Policy</privacyLink>.</paragraph>`
  },
  addressInformation: {
    heading: 'Address Information',
    tooltip: `<bold>Address Information:</bold>
              <paragraph>Your primary address is the primary physical location where you reside. If you wish to add or update an additional Mailing Address, Post Office (PO) Box, group billing address, special benefit address, or seasonal address please call Member Care Services.</paragraph>
              <paragraph>If you make an address change, you may receive a confirmation mailing from Thrivent verifying the information is accurate.</paragraph>`,
    stateTooltip: `<bold>State Information:</bold>
                  <paragraph>To add an address in an American Territory, please select the United States address type and select the appropriate territory from the State drop down menu. This includes the following territories: American Samoa (AS), Federated States of Micronesia (FM), Guam (GU), Marshall Islands (MH), Northern Mariana Islands (MP), Puerto Rico (PR), Palau (PW), and Virgin Islands (VI).</paragraph>
                  <paragraph>If you make an address change, you may receive a confirmation mailing from Thrivent verifying the information is accurate.</paragraph>`,
    street: '{{ street1 }}',
    street_multiline: '{{ street1 }}, {{ street2 }}',
    region: '{{ city }}, {{ state }} {{ postalCode }}',
    countryCode: '{{ countryCode }}'
  },
  actions: {
    edit: 'Edit'
  },
  labels: {
    legalName: 'Legal Name',
    lastName: 'Last Name',
    maritalStatus: 'Marital Status',
    email: 'Email',
    phone: 'Phone',
    phone_mobile: 'Mobile Phone',
    phone_home: 'Home Phone',
    phone_work: 'Work Phone',
    phone_mobilePhone: 'Mobile Phone',
    phone_homePhone: 'Home Phone',
    phone_workPhone: 'Work Phone',
    street1: 'Street Address',
    street1_US: 'Address 1',
    street2: 'Address 2',
    city: 'City',
    state: 'State/Province/Region',
    state_US: 'State',
    postalCode1: 'ZIP/Postal Code',
    postalCode1_US: 'ZIP Code',
    address: 'Residential Address',
    region: 'Region',
    country: 'Country',
    phoneType: 'unknown',
    phoneType_mobilePhone: 'Mobile Phone',
    phoneType_homePhone: 'Home Phone',
    phoneType_workPhone: 'Work Phone',
    addressType: 'Residential Address Type'
  },
  buttons: {
    cancel: 'Cancel',
    save: 'Save'
  },
  links: {
    home: 'Home',
    myThrivent: 'My Thrivent',
    backToMyThrivent: 'Back to My Thrivent',
    view: 'View',
    personalInformationPage: 'Personal Information Page',
    manageMyProfile: 'Manage My Profile'
  },
  phone: '{{value}}',
  phone_US: '{{value, phone}}',
  phoneType: 'unknown',
  phoneType_US: 'United States',
  phoneType_International: 'International',
  addressType: 'unknown',
  addressType_US: 'United States',
  addressType_International: 'International',
  helperText: {
    required: 'This field is required.'
  },
  requiredFields: '* indicates required field'
};
