import React, { memo } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Box,
  createStyles,
  withStyles,
  WithStyles,
  Theme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PhoneType } from '../../types';
import PhoneInput from '../PhoneInput/PhoneInput';

const styles = ({ breakpoints, palette: { errorRed } }: Theme) =>
  createStyles({
    formControlField: {
      display: 'block',

      [breakpoints.up('md')]: {
        marginBottom: '1.125rem'
      }
    },

    textField: {
      '& .Mui-error': {
        borderColor: errorRed
      },

      [breakpoints.up('sm')]: {
        maxWidth: '15.125rem'
      }
    },

    radioButtonLabel: {
      fontSize: '1rem'
    },

    radioLabel: {
      fontSize: '0.875rem'
    }
  });

interface Props extends WithStyles<typeof styles> {
  type: string;
  phoneValue: string;
  error?: string;
  nonUSIndicatorValue: string;
  onTypeChange: (evt: any) => void;
  onChange: (evt: any) => void;
}

const PhoneField: React.FC<Props> = ({
  classes,
  type,
  phoneValue,
  error,
  nonUSIndicatorValue,
  onChange,
  onTypeChange
}) => {
  const { t } = useTranslation('common');

  return (
    <FormControl component="fieldset" className={classes.formControlField}>
      <FormLabel htmlFor={`${type}Phone`} className={classes.radioButtonLabel}>
        {t('labels.phone', { context: type })}
      </FormLabel>
      <RadioGroup
        aria-label={`${type}-phone-type`}
        id={`${type}PhoneNonUSIndicator`}
        name={`${type}PhoneNonUSIndicator`}
        value={nonUSIndicatorValue}
        onChange={onTypeChange}
      >
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <FormControlLabel
            value="false"
            control={<Radio />}
            label={t('phoneType', { context: PhoneType.US })}
            classes={{ label: classes.radioLabel }}
          />
          <FormControlLabel
            value="true"
            control={<Radio />}
            label={t('phoneType', { context: PhoneType.International })}
            classes={{ label: classes.radioLabel }}
          />
        </Box>
      </RadioGroup>

      <PhoneInput
        handleChange={onChange}
        id={`${type}Phone`}
        name={`${type}Phone`}
        value={phoneValue}
        label=""
        error={error}
        textFieldClasses={{ root: classes.textField }}
        enableMask={nonUSIndicatorValue === 'false'}
      />
    </FormControl>
  );
};

export default withStyles(styles)(memo(PhoneField));
