import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common from './Translations/common';
import glossary from './Translations/glossary';
import validation from './Translations/validation';
import manageMyProfile from './Translations/manageMyProfile';
import sideRail from './Translations/sideRail';
import duplicatePhoneModal from './Translations/duplicatePhoneModal';
import invalidAddressModal from './Translations/invalidAddressModal';
import relatedLinks from './Translations/relatedLinks';
import errorScreen from './Translations/errorScreen';

i18n.use(initReactI18next).init({
  resources: {},
  lng: 'en',

  interpolation: {
    escapeValue: false,
    format: (value, formatStr) => {
      if (formatStr === 'currency') {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
      }

      if (formatStr === 'currencyShort') {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(
          Math.round(value)
        );
      }

      if (formatStr === 'phone') {
        return value
          .toString()
          .replace(/\D+/g, '')
          .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }

      return value;
    }
  }
});
i18n.addResourceBundle('en', 'common', common);
i18n.addResourceBundle('en', 'glossary', glossary);
i18n.addResourceBundle('en', 'validation', validation);
i18n.addResourceBundle('en', 'manageMyProfile', manageMyProfile);
i18n.addResourceBundle('en', 'sideRail', sideRail);
i18n.addResourceBundle('en', 'duplicatePhoneModal', duplicatePhoneModal);
i18n.addResourceBundle('en', 'invalidAddressModal', invalidAddressModal);
i18n.addResourceBundle('en', 'relatedLinks', relatedLinks);
i18n.addResourceBundle('en', 'errorScreen', errorScreen);

export default i18n;
