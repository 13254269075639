export default {
  one: {
    heading: 'Login Password',
    caption: 'Make changes to your password.'
  },
  two: {
    heading: 'Login Security',
    caption: 'Make changes to your security information.'
  },
  three: {
    heading: 'Preference Center',
    caption: 'Manage your communication choices.'
  },
  mobileHeading: 'More Actions'
};
