export default {
  headings: {
    sectionOne: 'My Information',
    sectionTwo: 'Need Help?',
    memberCare: 'Member Care Services'
  },
  id: 'Thrivent ID: {{ value }}',
  phone: '800-847-4836',
  hours: 'M - F: 7 a.m. - 6 p.m. (Central Time)',
  sendEmailLink: 'Send Secure Email',
  faqsLink: 'Visit FAQs',
  envelope: 'Secure',
  help: 'Help Available',
  goto: 'Go to $t(common:links.myThrivent)'
};
