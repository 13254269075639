import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import ErrorIcon from '../../assets/ErrorIcon.svg';

interface Props {
  show: boolean;
}

const ErrorIndicator: React.FC<Props> = ({ show }) => {
  const { t } = useTranslation('common');

  return (
    <Box marginLeft="1rem" marginRight="1rem">
      {show ? <img src={ErrorIcon} alt={t('helperText.required')} /> : null}
    </Box>
  );
};

export default memo(ErrorIndicator);
