import React, { useEffect, memo } from 'react';
import ReactDOM from 'react-dom';
import { useRouteMatch, withRouter, Switch, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Container, WithStyles, withStyles, createStyles, Theme } from '@material-ui/core';
import { useAuth, Header, Footer, useTrackPageViews } from 'thrivent-ui-library';

import ProfileInformation from './components/ProfileInformation';
import ManageMyProfile from './components/ManageMyProfile';
import SideRail from './components/SideRail';
import ErrorScreen from './components/ErrorScreen';
import SectionHeader from './components/SectionHeader';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const axe = require('react-axe');
  axe(React, ReactDOM, 1_000);
}

const styles = ({
  breakpoints,
  palette: {
    softWhite: { main: softWhiteMain }
  }
}: Theme) => {
  return createStyles({
    wrapper: {
      paddingTop: '1rem',
      backgroundColor: softWhiteMain,
      minHeight: 'calc(100vh - 468px)',

      [breakpoints.up('md')]: {
        paddingTop: '2.5rem'
      }
    },

    container: {
      paddingBottom: '5rem',

      [breakpoints.up('md')]: {
        paddingBottom: '7.5rem'
      }
    }
  });
};

const routes = [
  {
    name: 'Service Unavailable',
    path: '/manage-my-profile/service-unavailable',
    Component: ErrorScreen
  },
  {
    name: 'Personal Information',
    path: '/manage-my-profile/personal-information',
    Component: ProfileInformation
  },
  { name: 'Manage My Profile', path: '/manage-my-profile', Component: ManageMyProfile },
  { name: 'Manage My Profile', path: '/', Component: ManageMyProfile }
];

const LocationDisplay = withRouter(({ location }) => (
  <div data-testid="location-display">{`Current location ::: ${location.pathname}`}</div>
));

interface PrivateRouteProps {
  path: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
  return <Route {...rest} render={() => children} />;
};

const LOCAL_STORAGE_KEY = 'requestedPath';

const AppSwitch = () => {
  const location = useLocation<{ isRequestedPath: boolean }>();
  const { pathname } = location;
  const requestedPath = window.localStorage.getItem(LOCAL_STORAGE_KEY);

  if (requestedPath && requestedPath !== pathname) {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, '');
    window.location.href = requestedPath;
  }

  return (
    <Switch>
      {routes.map(({ path, Component }) => (
        <PrivateRoute key={path} path={path}>
          <Component />
        </PrivateRoute>
      ))}
    </Switch>
  );
};

interface Props extends WithStyles<typeof styles> {}

const App: React.FC<Props> = ({ classes }) => {
  const { t } = useTranslation('glossary');
  const { login, isLoggedIn } = useAuth();
  const { pathname } = useLocation();
  const errorScreenRouteMatch = useRouteMatch('/manage-my-profile/service-unavailable');

  useTrackPageViews('mythriventpersonalprofile', null);

  useEffect(() => {
    if (!isLoggedIn) {
      if (pathname && pathname !== '/') window.localStorage.setItem(LOCAL_STORAGE_KEY, pathname);
      login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, login]);

  useEffect(() => {
    return () => window.localStorage.setItem(LOCAL_STORAGE_KEY, '');
  }, []);

  if (!isLoggedIn) return null;

  return (
    <>
      <Helmet>
        <title>{t('brand')}</title>
      </Helmet>
      <Header />
      <SectionHeader />
      <div className={classes.wrapper}>
        {errorScreenRouteMatch && (
          <Container>
            <Box marginBottom="-2rem" display="block" width="100%">
              <AppSwitch />
            </Box>
          </Container>
        )}

        {!errorScreenRouteMatch && (
          <Container classes={{ root: classes.container }} disableGutters>
            <Grid container role="main" spacing={0}>
              <Grid item xs={12} md={8}>
                <AppSwitch />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box padding={{ xs: '0 1rem 0 1rem', md: '0 0 0 1.5rem' }}>
                  <SideRail />
                </Box>
              </Grid>
            </Grid>
          </Container>
        )}
        <Footer />
      </div>
      {process.env.NODE_ENV === 'test' && <LocationDisplay />}
    </>
  );
};

export default withStyles(styles)(memo(App));
