import React, { memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, Box, Button, Typography, createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import DefinitionList from '../DefinitionList';
import { ProfileForm } from '../../types';
import InfoTooltip from '../InfoTooltip';

const styles = ({
  breakpoints,
  palette: {
    common: { black },
    gray: { main: grayMain },
    blue: { dark: blueDark }
  }
}: Theme) =>
  createStyles({
    container: {
      opacity: 1,

      '&.inactive': {
        opacity: 0.6
      }
    },

    heading: {
      height: 'min-content',
      color: black
    },

    hidden: {
      visibility: 'hidden'
    },

    editButton: {
      fontSize: '0.75rem',

      '&:hover': {
        color: blueDark
      },

      [breakpoints.up('md')]: {
        fontSize: '0.875rem'
      }
    },

    collapse: {
      width: '100%'
    },

    requiredFields: {
      color: grayMain
    }
  });

interface Props extends WithStyles<typeof styles> {
  heading: string;
  tooltip: ReactNode;
  definitionTerms: ReactNode;
  formName: ProfileForm;
  profileForm: ReactNode;
  activeForm: ProfileForm;
  setActiveForm: () => void;
}

const ProfileInformationForm: React.FC<Props> = ({
  classes,
  formName,
  activeForm,
  setActiveForm,
  heading,
  tooltip,
  definitionTerms,
  profileForm
}) => {
  const { t } = useTranslation('common');
  const active = formName === activeForm || activeForm === ProfileForm.None;

  const toggleInformation = () => {
    setActiveForm();
  };

  const headingRef = React.useRef<HTMLInputElement>(null);

  const scrollToSectionHeading = () => {
    if (headingRef && headingRef.current) {
      headingRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      margin={{ xs: '1rem 0 0', md: '1.5rem 0 0' }}
      className={clsx(classes.container, !active && 'inactive')}
    >
      <div ref={headingRef}>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
          <Box display="flex" alignItems="flex-start">
            <Box display="flex" flexDirection="column">
              <Typography component="h2" variant="subtitle1" classes={{ root: classes.heading }}>
                {heading}
              </Typography>
              <Box
                marginTop="0.5rem"
                visibility={activeForm === formName && activeForm !== ProfileForm.None ? 'inherit' : 'hidden'}
              >
                <Typography variant="caption" classes={{ root: classes.requiredFields }}>
                  <em>{t('requiredFields')}</em>
                </Typography>
              </Box>
            </Box>

            <Box marginTop="-6px">
              <InfoTooltip title={<Box>{tooltip}</Box>} />
            </Box>
          </Box>

          <Box marginTop={{ xs: '-0.5rem', md: '-1rem' }}>
            <Button
              disabled={!active}
              color="secondary"
              className={clsx(classes.editButton, formName === activeForm ? classes.hidden : null)}
              onClick={toggleInformation}
            >
              {t('actions.edit')}
            </Button>
          </Box>
        </Box>

        {/* NOTE: margin varies due to the Edit button sizing - 6px is the difference between the required 24px spacing and the button padding and border width. */}
        <Box marginTop={activeForm === formName && activeForm !== ProfileForm.None ? '1.5rem' : '6px'}>
          <Collapse timeout={{ enter: 200, exit: 0 }} in={activeForm !== formName}>
            <DefinitionList>{definitionTerms}</DefinitionList>
          </Collapse>

          <Box width="100%" display="flex" bgcolor="white">
            <Collapse
              classes={{ root: classes.collapse }}
              in={activeForm === formName && activeForm !== ProfileForm.None}
              onExit={scrollToSectionHeading}
            >
              <>{profileForm}</>
            </Collapse>
          </Box>
        </Box>
      </div>
    </Box>
  );
};

export default withStyles(styles)(memo(ProfileInformationForm));
