export enum AddressType {
  US = 'US',
  International = 'International',
  None = 'unknown'
}

export enum PhoneType {
  US = 'US',
  International = 'International',
  None = 'unknown'
}

export enum ProfileForm {
  None,
  General,
  Contact,
  Address
}

export type ApiError = {
  id?: string;
  source?: string;
  status?: number;
  code: string;
  title: string;
  detail: string;
  errors: {
    code: string;
    status: number;
  }[];
};

// more info about this spec available here: https://jsonapi.org/
export type JsonResponse<T> = {
  data: null | T;
  error: ApiError;
  errors: ApiError[];
};

export enum ProfileValueType {
  COUNTRY_CODE = 'APH2-ISO-CTRY-CDE',
  STATE_CODE = 'ST-CDE',
  MARITAL_STATUS_CODE = 'MARTAL-STATUS-CODE'
}

export type ProfileValue = {
  type: ProfileValueType;
  id: string;
  attributes: {
    displayValue: string;
  };
};

export enum MaritalStatusCode {
  M, // 'Married'
  NA, // 'Not applicable'
  R, // 'Partnered'
  S, // 'Single'
  U, // 'Unknown'
  W, // 'Widowed'
  X // 'Separated'
}

export type Profile = {
  // index is used solely to create a diff that Formik uses to determine if a form should be
  // reinitialized with initialValues or not. Since CIF autocorrects some address fields, the enableReinitialize Formik
  // prop fails to function as needed. Incrementing the index attribute ensures a diff ocurrs and forms are initialized.
  index: number;
  id: string;
  email: string;
  mobilePhone: string;
  mobilePhoneNonUSIndicator: boolean;
  homePhone: string;
  homePhoneNonUSIndicator: boolean;
  workPhone: string;
  workPhoneNonUSIndicator: boolean;
  street1: string;
  street2: string;
  street3: string;
  street4: string;
  city: string;
  state: string;
  postalCode1: string;
  postalCode2: string;
  countryCode: string;
  maritalStatus: string;
  greetingName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  legalName: string;
  addressType: AddressType;
  checksum: number;
  hasFinancialProfile: boolean;
  [key: string]: any;
};

declare global {
  interface Window {
    WWW_DOMAIN: string;
    SERVICE_DOMAIN: string;
  }
}
